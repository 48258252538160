import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, message, Divider } from 'antd';
import utils from 'utils/util'
import service from './service';
import productService from '../ProductList/service'
import FormPage from 'components/TablePage/components/form'
import SearchType from 'utils/searchType'
import SelectProduct from 'components/SelectProduct'
import TextMore from 'components/TextMore'
import newsCategoryService from '../NewsCategory/service'

const Create = (props) => {
  let form = null;
  const { intl } = props;
  const { formatMessage } = intl;
  const id = utils.getQueryString('id')
  const [detail, setDetail] = useState({})
  const [productCategoryOptions, setProductCategoryOptions] = useState([])

  useEffect(() => {
    const fetchData = async (id) => {
      const data = await service.getDataDetail(id)
      setDetail(data.data)
    }
    if (!id) return
    fetchData(id)
  }, [id])

  useEffect(() => {
    const fetchData = async () => {
      const res = await newsCategoryService.getDatas()
      setProductCategoryOptions(res.data.map(i => ({ value: i.id, name: i.display_name })))
    }
    fetchData()
  }, [])

  const handleCreate = () => {
    form && form.validateFields((errs, values) => {
      if (!errs) {
        if (id) {
          service.updateData(id, values).then(data => {
            message.success('修改成功')
            handleCancel()
          })
        } else {
          service.createData(values).then(data => {
            message.success('创建成功')
            handleCancel()
          })
        }
      }
    })
  }

  const handleCancel = () => {
    props.history.goBack()
  }

  const handleChange = (values) => {
    setDetail(values)
  }

  const options = [
    { value: 'ONLINE', name: formatMessage({ id: 'online' }) },
    { value: 'OFFLINE', name: formatMessage({ id: 'offline' }) },
    // { value: 3, name: '初始' }
  ]
  const newsTypeOpts = [{ value: 'LINK', name: formatMessage({ id: 'link' }) }, { value: 'RAW', name: formatMessage({ id: 'content' }) }]
  const fields = [
    { name: 'title', desc: formatMessage({ id: 'title' }), form: { type: 'input' } },
    { name: 'sub_title', desc: formatMessage({ id: 'sub_title' }), form: { type: 'input', rules: [] } },
    { name: 'cover_image', desc: formatMessage({ id: 'cover' }), form: { type: 'upload', rules: [] } },
    { name: 'article_category_id', desc: formatMessage({ id: 'news_category' }), options: productCategoryOptions, form: { type: 'select' } },
    { name: 'images', desc: formatMessage({ id: 'images' }), form: { type: 'uploadImages', rules: [], initialValue: [] } },
    { name: 'ranking', desc: formatMessage({ id: 'ranking' }), form: { type: 'inputNumber', rules: [] } },
    {
      name: 'content_type', desc: formatMessage({ id: 'news_type' }), options: newsTypeOpts,
      form: { type: 'select', rules: [{ required: true, message: formatMessage({ id: 'message_select' }) + formatMessage({ id: 'news_type' }) }], initialValue: 'RAW' }
    },
    ...[
      detail && detail['content_type'] === 'LINK'
        ? { name: 'content', desc: formatMessage({ id: 'link' }), form: { type: 'input' } }
        : { name: 'content', desc: formatMessage({ id: 'content' }), form: { type: 'editor' } }
    ],
    // { name: 'description', desc: formatMessage({ id: 'content' }), form: { type: 'editor' } },
    // { name: 'link', desc: formatMessage({ id: 'link' }), form: { type: 'input' } },
    { name: 'status', desc: formatMessage({ id: 'status' }), options, form: { type: 'select', initialValue: 'ONLINE' } },
    { name: 'abstraction', desc: formatMessage({ id: 'description' }), form: { type: 'textarea', rules: [] } },
  ]

  return (
    <div>
      <div className="content-header">
        <h2>{formatMessage({ id: id ? 'title_edit' : 'title_create' })}{formatMessage({ id: 'news' })}</h2>
      </div>
      <div className="content-body">
        <FormPage {...props} ref={(forms) => form = forms} fields={fields} record={detail} handleChange={handleChange} />
        <Divider />
        <div style={{ textAlign: 'center' }}>
          <Button size='large' type="primary" onClick={handleCreate}>{formatMessage({ id: 'submit' })}</Button>
          <Button size='large' onClick={handleCancel} style={{ marginLeft: 10 }}>{formatMessage({ id: 'cancel' })}</Button>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Create);